import React from "react";

import {
  TAG_FEEL_GOOD,
  TAG_SHOW_ALL,
  TAG_THIS_IS_US,
  TAG_TECH,
  TAG_WELLBEING,
  TAG_CONFERENCE,
} from "../../utils/constants";

import "./Tags.scss";

export default function VideoTags({ activeTag, setTag }) {
  const handleActive = (tag) => {
    setTag(tag);
  };

  return (
    <>
      {activeTag === TAG_SHOW_ALL ? (
        <button className="tag tags-f active">Show All</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_SHOW_ALL)}
        >
          Show All
        </button>
      )}
      {activeTag === TAG_CONFERENCE ? (
        <button className="tag tags-f active">Conference</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_CONFERENCE)}
        >
          Conference
        </button>
      )}
      {activeTag === TAG_THIS_IS_US ? (
        <button className="tag tags-f active">This is Us</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_THIS_IS_US)}
        >
          This Is Us
        </button>
      )}
      {activeTag === TAG_FEEL_GOOD ? (
        <button className="tag tags-f active">Feel Good</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_FEEL_GOOD)}
        >
          Feel Good
        </button>
      )}
      {activeTag === TAG_WELLBEING ? (
        <button className="tag tags-f tag-Extralarge active">
          Wellbeing and Benefits
        </button>
      ) : (
        <button
          className="tag tags-f tag-Extralarge"
          onClick={() => handleActive(TAG_WELLBEING)}
        >
          Wellbeing and Benefits
        </button>
      )}
      {activeTag === TAG_TECH ? (
        <button className="tag tags-f active">Tech</button>
      ) : (
        <button className="tag tags-f" onClick={() => handleActive(TAG_TECH)}>
          Tech
        </button>
      )}
    </>
  );
}
