export const DEFAULT_VIDEO = "Watch Dwain’s latest video update from December";
export const DEFAULT_PODCAST = "Team Linehaul - Episode One";
export const TAG_SHOW_ALL = "Show All";
export const TAG_THIS_IS_US = "This is Us";
export const TAG_FEEL_GOOD = "Feel Good";
export const TAG_NETWORK = "Network";
export const TAG_TECH = "Tech";
export const TAG_HUB = "Hub";
export const TAG_LINEHAUL = "Linehaul";
export const TAG_CENTRAL_SERVICES = "Central Services";
export const TAG_CONFERENCE = "Conference";
export const TAG_WELLBEING = "Wellbeing and Benefits";
