import firebase from "firebase/app";
import "firebase/auth";

var config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
};

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const provider = new firebase.auth.SAMLAuthProvider("saml.aon");

export default app;
