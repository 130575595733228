import React, { useContext, useState, useEffect } from "react";
import { auth, googleProvider } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const verifyDPDEmail = (email) => {
  const splitEmail = email.split("@");
  const address = splitEmail[splitEmail.length - 1];
  if (address === "dpdgroup.co.uk") {
    return true;
  } else {
    return false;
  }
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuth, setAuth] = useState(false);

  function login() {
    return auth.signInWithPopup(googleProvider);
  }

  useEffect(() => {
    const unSub = auth.onAuthStateChanged((user) => {
      if (user && user.email && verifyDPDEmail(user.email)) {
        setCurrentUser(user);
        setAuth(true);
      }
    });
    return unSub;
  }, [isAuth]);

  const value = {
    currentUser,
    login,
    isAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
