import React from "react";

import {
  TAG_SHOW_ALL,
  TAG_NETWORK,
  TAG_HUB,
  TAG_LINEHAUL,
  TAG_CENTRAL_SERVICES,
} from "../../utils/constants";

import "./Tags.scss";

export default function PodcastTags({ activeTag, setTag }) {
  const handleActive = (tag) => {
    setTag(tag);
  };

  return (
    <>
      {activeTag === TAG_SHOW_ALL ? (
        <button className="tag tags-f active">Show All</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_SHOW_ALL)}
        >
          Show All
        </button>
      )}
      {activeTag === TAG_HUB ? (
        <button className="tag tags-f active">Hubs</button>
      ) : (
        <button className="tag tags-f" onClick={() => handleActive(TAG_HUB)}>
          Hubs
        </button>
      )}
      {activeTag === TAG_LINEHAUL ? (
        <button className="tag tags-f active">Linehaul</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_LINEHAUL)}
        >
          Linehaul
        </button>
      )}
      {activeTag === TAG_CENTRAL_SERVICES ? (
        <button className="tag tag-large tags-f active">
          Central Services
        </button>
      ) : (
        <button
          className="tag tags-f tag-large "
          onClick={() => handleActive(TAG_CENTRAL_SERVICES)}
        >
          Central Services
        </button>
      )}
      {activeTag === TAG_NETWORK ? (
        <button className="tag tags-f active">Network</button>
      ) : (
        <button
          className="tag tags-f"
          onClick={() => handleActive(TAG_NETWORK)}
        >
          Network
        </button>
      )}
    </>
  );
}
