import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import RecommendedCard from "./components/RecommendedCard/RecommendedCard";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer";
import VideoTags from "./components/Tags/VideoTags";
import PodcastTags from "./components/Tags/PodcastTags";
import dpdLogo from "./assets/newLogo.png";
import watch from "./assets/watch.svg";
import listen from "./assets/listen.svg";
import axios from "axios";
import { handleType } from "./utils/dom";
import { TAG_SHOW_ALL } from "./utils/constants";
import { useAuth } from "./context/AuthContext";
import { isVideoAvailable } from "./utils/available";
import GoogleButton from "react-google-button";

import "./app.scss";

function App() {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isWatch, setWatch] = useState(true);
  const [podcasts, setPodcasts] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState([]);
  const [tag, setTag] = useState(TAG_SHOW_ALL);
  const [error, setError] = useState(false);
  const { login, currentUser, isAuth } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const signIn = async () => {
      try {
        if (!isAuth && currentUser === null) {
          await login();
        }
      } catch (error) {
        console.log(error);
        // window.location.replace(process.env.REACT_APP_AON);
      }
    };

    signIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, currentUser, isAuth]);

  useEffect(() => {
    setLoading(true);
    const { videosJson } = require("./videos/video.json");
    setVideos(isVideoAvailable(videosJson));

    const fetchRSS = async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_RSS_URL);
        setPodcasts(data.rss);
      } catch (error) {
        setError(true);
      }
    };

    fetchRSS();

    setLoading(false);
  }, []);

  useEffect(() => {
    const defaultVideo = videos.filter((video) => video)[0];
    defaultVideo && setSelected(defaultVideo);
  }, [videos]);

  useEffect(() => {
    setSelectedPodcast(podcasts[0]);
  }, [podcasts]);

  const renderCards = () => {
    return tag === TAG_SHOW_ALL
      ? videos.map((video, index) => {
          return video.id !== selectedVideo.id ? (
            <RecommendedCard
              className="media-card"
              video={video}
              setVideo={setSelected}
              key={index}
            />
          ) : null;
        })
      : videos
          .filter((video) => video.tags[0] === tag)
          .map((video, index) => {
            return video.id !== selectedVideo.id ? (
              <RecommendedCard
                className="media-card"
                video={video}
                setVideo={setSelected}
                key={index}
              />
            ) : null;
          });
  };

  const renderListen = () => {
    return tag === TAG_SHOW_ALL
      ? podcasts.map((podcast, index) => {
          return selectedPodcast ? (
            podcast.video_title !== selectedPodcast.video_title ? (
              <RecommendedCard
                className="media-card"
                video={podcast}
                setVideo={setSelectedPodcast}
                key={index}
                isPodcast
              />
            ) : null
          ) : null;
        })
      : podcasts
          .filter((podcast) => podcast.video_title.includes(tag))
          .map((podcast, index) => {
            return selectedPodcast ? (
              podcast.video_title !== selectedPodcast.video_title ? (
                <RecommendedCard
                  className="media-card"
                  video={podcast}
                  setVideo={setSelectedPodcast}
                  key={index}
                  isPodcast
                />
              ) : null
            ) : null;
          });
  };

  const renderPlayers = () => {
    if (!loading) {
      if (isWatch) {
        return (
          <VideoPlayer
            share_url={selectedVideo.share_url}
            description={selectedVideo.description}
            date={selectedVideo.date_uploaded}
            length={selectedVideo.length}
            title={selectedVideo.video_title}
            videoId={selectedVideo.videoId}
            tag1={selectedVideo.scriptTag1}
            tag2={selectedVideo.scriptTag2}
            user={currentUser}
            list_title={selectedVideo.list_title}
            list={selectedVideo.list}
            end_para={selectedVideo.end_para}
          />
        );
      } else {
        return (
          selectedPodcast && (
            <AudioPlayer
              videoURL={selectedPodcast.url}
              description={selectedPodcast.description}
              date={selectedPodcast.date_uploaded}
              length={selectedPodcast.length}
              title={selectedPodcast.video_title}
              thumbnail={selectedPodcast.thumbnail}
            />
          )
        );
      }
    } else {
      return null;
    }
  };

  const renderApp = () => {
    return (
      <Container fluid className="main-container">
        <nav role="navigation">
          <img className="logo-img" src={dpdLogo} alt="" />
        </nav>
        <Row className="video-play-row">
          <Col xl={7}>{renderPlayers()}</Col>
          <Col className="line-container" xl={1}>
            <div className="line"></div>
          </Col>
          <Col xl={4} className="content">
            <div className="content-type">
              <div
                className="watch-container"
                onClick={() => handleType(setWatch, setTag, true)}
              >
                <img
                  className="watch-logo"
                  src={watch}
                  alt="tv screen"
                  type="image"
                />
                <p className="watch">Watch</p>
              </div>
              <div
                className="listen-container"
                onClick={() => handleType(setWatch, setTag, false)}
              >
                <img
                  className="listen-logo"
                  src={listen}
                  alt="radio waves"
                  type="image"
                />
                <p className="listen">Listen</p>
              </div>
            </div>
            <div className="tags-container">
              {isWatch ? (
                <VideoTags activeTag={tag} setTag={setTag} />
              ) : (
                <PodcastTags activeTag={tag} setTag={setTag} />
              )}
            </div>
            <ul className="list-unstyled">
              {videos.length > 0 && isWatch ? renderCards() : renderListen()}
            </ul>
          </Col>
        </Row>
      </Container>
    );
  };

  if (currentUser && isAuth) {
    return renderApp();
  } else {
    return (
      <div className="ForcedLoginBackground">
        <div className="ForcedLoginContainer">
          <img
            className="watch-logo"
            src={watch}
            alt="tv screen"
            type="image"
            style={{ marginBottom: 25 }}
          />
          <h3>DPD Media</h3>
          <hr />
          <GoogleButton onClick={login} />
          {console.log(error)}
        </div>
      </div>
    );
  }
}

export default App;
