export const handleType = (setWatch, setTag, isWatch) => {
  const watch = document.getElementsByClassName("watch-container")[0];
  const listen = document.getElementsByClassName("listen-container")[0];
  if (isWatch) {
    setWatch(true);
    setTag("Show All");
    watch.style.borderBottom = "2px solid #dc0032";
    listen.style.borderBottom = "unset";
    listen.style.marginBottom = "unset";
    watch.style.marginBottom = "-2px";
  } else {
    setWatch(false);
    setTag("Show All");
    listen.style.borderBottom = "2px solid #dc0032";
    watch.style.borderBottom = "unset";
    watch.style.marginBottom = "unset";
    listen.style.marginBottom = "-2px";
  }
};
