import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { WhatsappShareButton, WhatsappIcon } from "react-share";

import "./AudioPlayer.scss";

export default function AudioPlayer({
  videoURL,
  description,
  date,
  length,
  title,
}) {
  const formatedDate = date.split(" ");
  const formateLength = (length) => {
    const lengthArray = length.split(":");

    return `${lengthArray[0]} mins ${lengthArray[1]} secs`;
  };

  const videoID = videoURL.split("/");
  const shareURL = `https://share.fireside.fm/episode/${
    videoID[videoID.length - 1]
  }`;

  return (
    <div className="audio-player">
      <div className="audio-container">
        <div className="responsive-audio-container">
          <iframe
            title={title}
            className="responsive-audio"
            id="audio-player"
            allow="autoplay; fullscreen"
            data-vimeo-loop="true"
            frameBorder="0"
            src={videoURL}
          ></iframe>
        </div>
      </div>
      <div className="share-container">
        <div>
          <p className="small-text">All media is for internal use only.</p>
          <p className="small-text">
            Sharing media with anyone outside of DPDgroup is prohibited.
          </p>
        </div>
        <WhatsappShareButton url={shareURL}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <Container fluid className="details-container">
        <Row className="details-row">
          <Col>
            <div className="details">
              <div>
                <h5 className="h100">Details</h5>
                <p className="date-mobile t200">{`${formatedDate[1]} ${formatedDate[2]} ${formatedDate[3]}`}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="description-row">
          <Col md={9}>
            <p className="description t300">{description}</p>
          </Col>
          <Col md={3}>
            <div>
              <p className="date t200">{`${formatedDate[1]} ${formatedDate[2]} ${formatedDate[3]}`}</p>
              <p className="length t100">{formateLength(length)}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
