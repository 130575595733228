import React from "react";
import Media from "react-bootstrap/Media";

import "./Card.scss";
import playButton from "../../assets/playButton.svg";

export default function RecommendedCard({ video, setVideo, isPodcast }) {
  const handleClick = () => {
    setVideo(video);
  };

  return (
    <>
      <Media as="li" onClick={() => handleClick()}>
        <div className="thumbnail">
          <img
            width={isPodcast ? 200 : 205}
            height={isPodcast ? 200 : 130}
            className={
              isPodcast
                ? "mr-3 card-image podcast-img-media"
                : "mr-3 card-image"
            }
            src={video.thumbnail}
            alt=""
          />
          <img className="play-button" src={playButton} alt="play buttom" />
        </div>
        <Media.Body>
          <h5 className="card-title t800">{video.video_title}</h5>
          <p className="card-text t400">{video.description}</p>
        </Media.Body>
      </Media>
    </>
  );
}
