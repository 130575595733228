import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import axios from "axios";
import "./VideoPlayer.scss";

export default function VideoPlayer({
  description,
  date,
  length,
  title,
  videoId,
  tag1,
  tag2,
  share_url,
  user,
  list_title,
  list,
  end_para
}) {
  const [loading, setLoading] = useState(true);

  const trackingEmail = `${user.uid}@dpdgroup.co.uk`;

  useEffect(() => {
    const oldTags = document.querySelectorAll("#wista-tag");

    if (oldTags.length > 0) {
      oldTags.forEach((element) => {
        element.remove();
      });
    }

    const getScriptTag = async (url) => {
      try {
        setLoading(true);
        const { data } = await axios.get(url);
        const scriptTag = document.createElement("script");
        scriptTag.id = "wista-tag";
        scriptTag.async = true;
        scriptTag.innerText = data;
        document.body.appendChild(scriptTag);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getScriptTag(tag1);
    getScriptTag(tag2);
  }, [tag1, tag2]);

  return (
    !loading && (
      <>
        <div className="video-player">
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <div
                className={`wistia_embed wistia_async_${videoId} videoFoam=true email=${trackingEmail}`}
              ></div>
            </div>
          </div>
          <div className="share-container share-vdeo">
            <div>
              <p className="small-text">All media is for internal use only.</p>
              <p className="small-text">
                Sharing media with anyone outside of DPDgroup is prohibited.
              </p>
            </div>
            <WhatsappShareButton url={share_url}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <Container fluid className="details-container">
            <Row className="details-row">
              <Col>
                <div className="details">
                  <div>
                    <h5 className="h100">{title}</h5>
                    <p className="date-mobile t200">{date}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="description-row">
              <Col md={9}>
                <p className="description t300">{description}</p>

                {list_title &&
                  <p>{list_title}</p>
                }

                {list &&
                  <ul>
                    {list.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )
                    })}
                  </ul>
                }

                {end_para &&
                  <p>{end_para}</p>
                }
              </Col>
              <Col md={3}>
                <div>
                  <p className="date t200">{date}</p>
                  <p className="length t100">{length}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  );
}
